.leaderboard {
    position: relative;
    padding: 1vw 2vw;
    height: 100%;

    .not_open {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;
        font-size: 2vw;
    }

    .table {
        width: 100%;
        height: 100%;
        text-align: center;

        * {
            font-family: 'bold';
            text-transform: uppercase;
        }

        .thead {
            background: white;
            color: black;

            .th {
                padding: 1vw 0;
                font-size: 1.6vw;
                color: darkslategray;
            }
        }

        .tbody {
            overflow-y: scroll;
            height: 88%;
            padding-top: 1vw;
        }

        .tr {
            display: flex;
            width: 100%;

            .th, .td {
                padding: 1vw 0;
                font-size: 1.6vw;

                &:nth-child(1) {
                    width: 10%;
                }

                &:nth-child(2) {
                    width: 10%;
                }

                &:nth-child(3) {
                    width: 30%;
                }

                &:nth-child(4) {
                    width: 50%;
                }
            }

            .td:last-child {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                span {
                    display: inline-block;
                    flex-basis: calc(100%/3);
                    margin-bottom: 1vw;
                    text-transform: initial;
                }
            }
        }
        
        
       
    }
}
