@import 'reset';
@import 'font';

$mobile : 900px;

html, body {
    overflow: hidden;
}

#Main{
    background: var(--bg-base) center center no-repeat;
    background-size: cover;
}

* {
    font-family: 'regular';
    color: lightgray;

    &::-webkit-scrollbar {
        width: .3vw;
        height: .1vw;
    }
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: #fde50d;
        border: 0px none #ffffff;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:active {
        background: #000000;
    }
    &::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #ffffff;
    }
    &::-webkit-scrollbar-track:hover {
        background: #666666;
    }
    &::-webkit-scrollbar-track:active {
        background: #333333;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.customOverlay {
    background: rgba(0, 0, 0, 0.7);
}

.customModal {
    padding: 4vw 2vw 2vw;
    background-color: transparent;
    background-image: var(--bg-modal);
    background-position: center;
    background-size: 100% 100%;
    height: 70%;
    width: 60%;
    max-width: initial;
    font-size: 2vw;

    @media screen and (max-width: $mobile) {
        height: 80%;
        width: 80%;
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .content {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        * {
            font-size: 2vw;
        }

    }

    .login {
        .btn-cont {
            text-align: center;
        }
        .btn {
            position: relative;
            display: inline-block;
            margin-top: 4vw;
            cursor: pointer;

            img {
                width: 13vw;
            }

            p {
                font-family: 'bold';
                color: #000;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                position: absolute;
            }
        }
    }

    .prize {
        img {
            width: 8vw;
        }

        h2 {
            font-size: 3vw;
        }
        
        p {
            &:nth-of-type(1) {
                font-size: 2vw;
                width: 50%;
                margin: auto;
                margin-bottom: 2vw;
                line-height: 1.5;
            }

            &:nth-of-type(2) {
                margin-top: 2vw;
            }
        }
    }

    .confirm {
        position: relative;
        height: 100%;

        > p {
            position: absolute;
            top: 25%;
            left: 0;
            right: 0;
            width: 80%;
            margin: auto;
            line-height: 1.3;
        }

        .btn-cont {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;

            .btn {
                position: relative;
                width: calc(100% / 2);
                margin: 0 1vw;
                cursor: pointer;

                img {
                    width: 100%;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    transform: translateY(-50%);
                    color: darkslategray;
                    font-family: 'bold';
                    font-size: 2vw;
                }
            }
        }
    }
}

.customCloseButton {
    background-image: var(--btn-close);
    background-size: 100% 100%;
    top: 0;
    right: 0;

    svg {
        visibility: hidden;
        width: 2.5vw;
        height: 2.5vw;

        @media screen and (max-width: $mobile) {
            width: 4vw;
            height: 4vw;
        }
    }
}