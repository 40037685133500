.unregistered {
  background:var(--tournament-bg-sponsor)
    bottom left;
  background-size: cover;
  height: 100vh;
  position: relative;

  //ROG PHONE
  @media (orientation: landscape) and (min-aspect-ratio: 225/100) {
   background-size: 80%;
   background-repeat: no-repeat;
   background-position-x:70%;
  }

  .content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    //IPAD AIR
    @media (orientation: landscape) and (max-aspect-ratio: 100/65) {
      width: 40vw;
      top: 50%;
      transform: translateY(-50%);
      height: 60%;
    }

    //REGULAR IPAD
    @media (orientation: landscape) and (max-aspect-ratio: 14/10) {
      width: 34vw;
      top: 50%;
      transform: translateY(-50%);
      height: 55%;
    }

    //ROG PHONE
    @media (orientation: landscape) and (min-aspect-ratio: 225/100) {
      width: 40vw;
      right:8%;
    }

    .button {
      margin: 0 auto;
      width: 25vw;
      cursor: pointer;

      @media (orientation: landscape) and (min-width: 2000px) {
        width: unset;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .board {
    margin: 0 auto;
    width: 70%;

    .board_button {
      display: flex;

      .button {
        width: calc(100% / 2);
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        img {
          width: 100%;
          visibility: hidden;
        }

        &.info {
          background: var(--btn-info-hadiah-inactive);
          background-size: 100% 100%;

          &.active,
          &:hover {
            background: var(--btn-info-hadiah-active);
            background-size: 100% 100%;
          }
        }

        &.rule {
          background: var(--btn-peraturan-inactive);
          background-size: 100% 100%;

          &.active,
          &:hover {
            background: var(--btn-peraturan-active);
            background-size: 100% 100%;
          }
        }
      }
    }

    .board_content {
      width: 100%;
      background: var(--board)
        top center;
      background-size: 100% 100%;

      > div {
        display: none;

        &.active {
          display: block;
        }
      }

      .hadiah {
        position: relative;

        img {
          padding: 0.8vw 0 0 0;
          width: 100%;
        }

        p {
          position: absolute;
          bottom: 15%;
          color: white;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 1.2vw;
        }
      }

      .peraturan {
        position: relative;

        * {
          font-size: 1vw;
          line-height: 1.5;
        }

        img {
          padding: 0.8vw 0 0 0;
          visibility: hidden;
          width: 100%;
        }

        > div {
          position: absolute;
          top: 1.5vw;
          width: 98%;
          padding: 0 8% 0 10%;
          margin-right: 2%;
          overflow-y: scroll;
          height: 90%;
        }

        ul {
          padding-left: 1.1vw;
        }
      }
    }
  }
}
