.rules {
    padding: 2vw 10%;
    overflow-y: scroll;
    height: 100%;

    * {
        font-size: 1.5vw;
        line-height: 1.5;
    }

    h3 {
        font-size: 2vw !important;
    }

    ul {
        padding-left: 1.1vw;
    }

    b,strong{
        font-weight: bold !important;
    }
}
