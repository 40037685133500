.rewards {
    padding: 0 10% 2vw;
    overflow-y: scroll;
    height: 100%;

    h3 {
        color: yellow;
        font-size: 2vw;
        margin-bottom: 1vw;
    }

    p {
        font-size: 1vw;
        margin-bottom: 1vw;

        img {
            max-width: 8vw;
            margin-right: 1vw;
        }
    }

    .items {
        display: flex;
        .item {
            background: red;
            width: 5vw;
            height: 5vw;
        }
    }

    .content {
        margin: 2vw 0;
    }

    .prize_cont {
        display: flex;

        .prize {
            position: relative;
            width: 6vw;
            height: 6vw;
            background: var(--rewards-purple);
            background-size: 100% 100%;
            margin-right: 1vw;

            img {
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

}
