.qualification {
    padding: 1vw;
    margin-right: .5vw;
    max-height: 100%;
    overflow-y: scroll;
}

.timeline {
    display: flex;
    text-align: center;
    justify-content: space-between;
    font-size: 1.2vw;
    padding: 0 1vw;
    .timeline_item {
        border: solid 1px white;
        background-color: transparent;
        width: calc(100% /5.2);
        padding: 1vw .5vw;
        border-radius: 0.3vw;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &.active, &:hover {
            background-color: #ffe700;
            border-color: #000;

            * {
                color: #000;
            }
        }

        &.disabled {
            pointer-events: none;
            background-color: gray;
            * {
                color: #000;
            }
        }

        div {
            &:nth-child(1) {
                font-family: 'bold';
                font-size: 1.3vw;
                margin-bottom: .2vw;
            }

            &:nth-child(2) {
                font-family: 'medium';
                font-size: 1.1vw;
            }
        }
    }
}

.not_open {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    font-size: 2vw;
}

.stage {
    display: flex;
    margin: 1.2vw;
    margin-bottom: 3vw;
    align-items: center;
    background: linear-gradient(to top, #413e4d, #00000000);
    border: solid 1px #ffffff55;
    padding: 1vw 1.2vw;
    justify-content: space-between;

    div {
        font-family: 'bold';
        font-size: 1.8vw;
        line-height: 1;
        min-width: fit-content;
        color: lightgray;
    }

    .create_team {
        text-align: center;
        width: 100%;
    }
}

.progress {
    padding: 0 5vw;

    .title {
        font-size: 1.5vw;
        margin-bottom: .5vw;
        font-family: 'bold';
    }

    .bar {
        position: relative;
        height: 2.5vw;
        .progress_bar {
            background: linear-gradient(to top, #ffffff66, #00000000);
            width: 100%;
            height: 100%;
        }

        .current_progress {
            top: 0;
            position: absolute;
            background: #fde50d;
            width: 0%;
            height: 100%;
        }

        .limiter {
            position: absolute;
            width: 0.1vw;
            height: 120%;
            left: 50%;
            background: #fde50d;
            top: -10%;

            .point {
                text-align: center;
                position: absolute;
                width: 20vw;
                left: -5vw;
                top: 3.3vw;
                font-size: 1.5vw;
                color: #fde50d;                
                font-family: 'bold';
            }
        }

    }
}

.round {
    padding: 1vw 5vw;
    margin-top: 2vw;

    .title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: .5vw 0;
        
        .text {
            font-size: 1.5vw;
            font-family: 'bold';
        }

        .stats {
            align-items: center;
            display: flex;
            .stat_item {
                display: flex;
                align-items: center;
                .icon {
                    width: 6vw;
                    img {
                        max-width: 100%;
                    }
                }

                .text {
                    margin: 0 1vw;
                }
            }
        }
    }
    .round_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;

        .round_item {
            width: calc(100%/10.5);
            margin-bottom: .5vw;
            img {
                width: 100%;
            }
        }
    }
}

.prizes {
    display: flex;
    justify-content: flex-end;
    margin: 0 1.2vw;
    
    .prize {
        display: flex;
        align-items: center;
        margin-right: 1.5vw;
    
        .text p{
            text-align: right;
            font-size: 1.2vw;
            margin: .2vw 0;
        }
    
        .icon {
            width: 4vw;
            height: 4vw;
            margin-left: 1vw;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
            }

            &.active {
                filter: drop-shadow(0 0 4px yellow);
            }

            &.disabled {
                filter: grayscale(1);
                pointer-events: none;
            }
        }

        &:last-child {
            margin: 0;
        }
    }
}

.team_form {
    margin: 1.2vw 10%;

    * {
        font-size: 1.5vw;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .field {
        margin-bottom: 1vw;
        display: flex;
        align-items: center;

        label {
            width: 9vw;
            margin-right: 1vw;
            font-family: 'bold';

            span {
                font-size: 1vw;
            }
        }

        input {
            background: transparent;
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.3);
            padding: .2vw .5vw;

            &:read-only {
                border: 0;
                outline: 0;
            }
        }

        .btn {
            margin-left: 1vw;
            display: inline-block;
            width: 6vw;

            img {
                width: 100%;
            }
        }
    }

    .team_name {
        margin-bottom: 2vw;
        display: flex;
        // justify-content: space-between;

        > div {
            width: 50%;
        }

        .btn_cont {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 3%;

            .btn_submit {
                display: inline-block;
                background: transparent;
                border: 0;
                padding: 0;
                width: 12vw;
                cursor: pointer;

                &:focus {
                    outline: 0;
                }
    
                img {
                    width: 100%;
                }
            }

            .btn_bubar {
                margin-right: 1vw;
            }
        }

    }

    .team_member {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .field {
            width: 50%;
        }

        .input_cont {
            position: relative;
        }

        .kick_btn {
            z-index: 99;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0.5vw;
            font-family: 'bold';
            cursor: pointer;
        }
    }
}

.team_detail {
    margin: -2vw 1.2vw 1.3vw 1.2vw;
    font-size: 1.5vw;
    border: 1px solid #fff;

    .row {
        display: flex;
        align-items: center;

        .col {
            text-align: center;
            padding: .5vw 0;

            &:nth-child(1) {
                width: 17.5%;
            }
            &:nth-child(2) {
                width: 20%;
            }
            &:nth-child(3) {
                width: 17.5%;
            }
            &:nth-child(4) {
                width: 45%;
            }
        }

        &:nth-child(1) {
            background-color: #fff;
            .col {
                font-family: 'bold';
                color: darkslategray;
            }
        }

        &:nth-child(2) {
            padding: .5vw 0;

            .col {
                &:nth-child(4) {
                    display: flex;
                    flex-wrap: wrap;

                    p {
                        width: calc(100%/3);
                        padding: .3vw 0;
                    }
                } 
            }
        }
        
        
    }
}

.team_view {
    
    .bottom_cont {
        display: flex;
        justify-content: space-between;
        margin: 0 5vw;
    }

    .prizes {
        justify-content: initial;
        align-items: center;

        .text p {
            text-align: right;
        }
    }

    .bubar {
        width: 12vw;
        display: inline-flex;
        align-items: center;
        img {
            width: 100%;
        }
    }
}

.invitation_cont {
    margin: -2vw 1.2vw 1.3vw 1.2vw;
    font-size: 1.5vw;
    
    .row {
        display: flex;
        align-items: center;

        .col {
            text-align: center;
            padding: .5vw 0;

            &:nth-child(1) {
                width: 20%;
            }
            &:nth-child(2) {
                width: 20%;
            }
            &:nth-child(3) {
                width: 20%;
            }
            &:nth-child(4) {
                width: 40%;
            }
        }

        &:nth-child(1) {
            background-color: #fff;
            padding: .3vw 0;

            .col {
                font-family: 'bold';
                color: darkslategray;
                font-size: 1.7vw;
            }
        }

        &:nth-child(2) {
            background-color: rgba(0, 0, 0, 0.522);

            .col {
                padding: 1.5vw 0;

                &:nth-child(3) {
                    div {
                        margin-bottom: .8vw;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &:nth-child(4) {
                    .btn {
                        width: 15vw;
                        margin: auto;
                        margin-bottom: .5vw;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.not_qualified {
    position: relative;
    height: 18vw;

    > div {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);

        span {
            display: block;
            font-family: 'medium';
            font-size: 1.5vw;
            line-height: 1.3;
        }
    }
}