.layout {
  width: 100%;
  height: 100vh;
  color: white;
  background: var(--background)
    top center no-repeat;
  background-size: 100%;
  position: relative;
  padding: 14vw 2vw 0;

  //ROG PHONE
  @media (orientation: landscape) and (min-aspect-ratio: 225/100) {
    padding: 8vw 2vw 0;
    background-position-y: 10%;
  }

  .content {
    bottom: 2vw;
    height: 70%;
    width: 100%;

    .tab {
      display: flex;
      justify-content: space-evenly;

      .tab_item {
        width: calc(100% / 4.5);
        display: block;
        background-size: 100% 100%;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:nth-child(1) {
          background-image: var(--btn-menu-info-hadiah-inactive);

          &.active,
          &:hover {
            background-image: var(--btn-menu-info-hadiah-active);
          }
        }

        &:nth-child(2) {
          background-image: var(--btn-menu-kualifikasi-inactive);

          &.active,
          &:hover {
            background-image: var(--btn-menu-kualifikasi-active);
          }
        }

        &:nth-child(3) {
          background-image: var(--btn-menu-leaderboard-tim-inactive);

          &.active,
          &:hover {
            background-image: var(--btn-menu-leaderboard-tim-active);
          }
        }

        &:nth-child(4) {
          background-image: var(--btn-menu-peraturan-inactive);

          &.active,
          &:hover {
            background-image: var(--btn-menu-peraturan-active);
          }
        }

        img {
          visibility: hidden;
          max-width: 100%;
        }
      }
      margin-bottom: -1.2vw;
    }

    .board {
      position: relative;
      z-index: 99;
      padding: 1vw 0;
      margin: -1.3vw auto 0;
      height: 56vh;
      background: var(--menu-board);
      background-size: cover;

      //IPAD AIR
      @media (orientation: landscape) and (max-aspect-ratio: 100/65) {
        height: 52vh;
      }

      //REGULAR IPAD
      @media (orientation: landscape) and (max-aspect-ratio: 14/10) {
        height: 48vh;
      }

      //ROG PHONE
      @media (orientation: landscape) and (min-aspect-ratio: 225/100) {
      height: 61vh;
      }
    }
  }
}
