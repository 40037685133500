@font-face {
    font-family: 'regular';
    src: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/fonts/Gunar.ttf');
}

@font-face {
    font-family: 'medium';
    src: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/fonts/Gunar Medium.ttf');
}

@font-face {
    font-family: 'bold';
    src: url('https://cdngarenanow-a.akamaihd.net/webid/CODM/fonts/Gunar Bold.ttf');
}